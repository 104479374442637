import {FetchService} from '../fetch/fetch.service';
import {InventoryPage, MyShopLocation, ObsoleteItem, ScannedLocation} from '../../shared/my-shop/my-shop.class';
import {StandardJSONResponse} from '../../shared/tools/AS.types';

export class MyShopService {
    constructor(private _fetchService: FetchService) {}

    /**
     * Add an item to a shop location
     * @param item
     * @param binID
     */
    public addItemToLocation(item: string | string[], binID: string) {
        return this._fetchService.post<StandardJSONResponse | StandardJSONResponse[]>(`/api/my-shop/addItemToLocation`, {
            item,
            binID,
        });
    }

    /**
     * Delete a shop location
     * @param binID
     */
    public deleteLocation(binID: string) {
        return this._fetchService.post<StandardJSONResponse>(`/api/my-shop/deleteLocation`, {
            binID,
        });
    }

    /**
     * Take in a new name and old name to rename a shop location
     * @param oldName
     * @param newName
     */
    public editLocation(oldName: string, newName: string) {
        return this._fetchService.post<StandardJSONResponse>(`/api/my-shop/edit`, {
            oldName,
            newName,
        });
    }

    /**
     * Remove items from a shop location
     * @param page
     * @param sortBy
     * @param filterItem
     */
    public getInventoryPage(page?: string, sortBy?: string, filterItem?: string) {
        return this._fetchService.post<InventoryPage>(`/api/my-shop/getInventoryPage`, {
            filterItem,
            page,
            sortBy,
        });
    }

    /**
     * Returns detail for provided location
     * @param bin - My shop location to get detail for
     */
    public getLocationDetail(bin: string) {
        return this._fetchService.get<MyShopLocation>(`/api/SampleList/${bin}`);
    }

    /**
     * Get all locations from the API server
     */
    public getLocations() {
        return this._fetchService.get<MyShopLocation[]>(`/api/my-shop/getLocations`);
    }

    /**
     * Remove items from a shop location
     * @param items
     * @param binID
     */
    public removeItemsFromLocation(items: string[], binID: string) {
        return this._fetchService.post<StandardJSONResponse>(`/api/my-shop/removeItemsFromLocation`, {items, binID});
    }

    /**
     * Replaces oldItemNum with newItemNum in binID
     * @param oldItemNum - Item to remove
     * @param newItemNum - Item to add
     * @param binID - Shop location to make replacement
     */
    public replaceItem(oldItemNum: string, newItemNum: string, binID: string) {
        return this._fetchService.post<StandardJSONResponse>(`/api/my-shop/replaceItem`, {
            oldItemNum,
            newItemNum,
            binID,
        });
    }

    /**
     * Submits a batch of obsolete items to term
     * @param obsoleteItems
     */
    public submitObsoleteItems(obsoleteItems: ObsoleteItem[]) {
        return this._fetchService.post<StandardJSONResponse>(`/api/my-shop/submitObsoleteItems`, obsoleteItems);
    }

    /**
     * Submits a batch of scanned locations to term
     * @param scannedLocations
     */
    public submitScannedLocations(scannedLocations: ScannedLocation[]) {
        return this._fetchService.post<StandardJSONResponse>(`/api/my-shop/submitScannedLocations`, scannedLocations);
    }
}
